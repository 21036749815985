export const enTranslation = {
  en: "English",
  tc: "繁體中文",
  sc: "简体中文",
  hkd: " Hong Kong Dollar",
  rmb: " Chinese Yuan",
  usd: " United States Dollar",
  eur: " Euro",
  sgd: " Singapore Dollar",
  local: "Local Bank",
  international: "Overseas Bank (SWIFT)",
  australia: "Australia",
  german: "Germany",
  india: "India",
  indonesia: "Indonesia",
  china: "Mainland China",
  malaysia: "Malaysia",
  "new-zealand": "New Zealand",
  philippines: "Philippines",
  singapore: "Singapore",
  taiwan: "Taiwan",
  "united-kingdom": "UK",
  "united-states": "United States",
  vietnam: "United States",
  other: "other",

  account: "Account Number",
  transfer_in_label: "Transfer In",
  transfer_out_label: "Transfer Out",
  from: "From",
  to: "To",
  balance: "balance",
  apply_now: "Apply Now",
  apply_here: "Apply Here.",
  apply_note_1: "Application will be processed within 1 working day",
  apply_note_2:
    "After a successful review, we will send the account information to your email.",
  apply_step_1: "1. Please fill in the Personal Information Form",
  apply_step_2: "2. Send the completed form to",
  apply_step_3: "3. Please wait patiently for 2-3 working days",
  apply_step_4:
    "4. After a successful review, we will send the account information to your email.",
  download: "download",
  login: "Login",
  form_header: "Welcome",
  login_to_account: "Log in to your account",
  username: "Username",
  without_account: "No Account ?",
  password: "Password",
  desc1: "All-in-one trust asset management service provider",
  desc2:
    "As a global financial institution headquartered in Hong Kong, Rich Dragon Trust is collectively built by experts from various fields such as trust, asset management, financial technology, legal compliance, financial services, credit cards, and payments. It offers standardized customer service solutions.",
  desc3:
    "Rich Dragon is a trust company registered, insured and licensed with reference to Section 77 of Part III of the Trust Act. License No. TC009033.",
  change_pwd: "Change Password",
  old_password: "Old Password",
  new_password: "New Password",
  confirm_new_password: "Confirm New Password",
  display_name: "Display Name",
  edit: "Edit",
  account_password: "Account Password",
  account_center: "Account Center",
  edit_account_password: "Edit Account Password",
  handling_fee: "Handling Fee",
  message: {
    missing_username: "Please fill in username",
    missing_password: "Please fill in password",
    missing_amount: "Please fill in amount",
    missing_asset_type: "Please select asset type",
    missing_to_address: "Please fill in to address",
    missing_dates: "Please select the start date/end date",
    missing_account_name: "Please fill in account name",
    missing_account_number: "Please fill in account number",
    missing_bank_name: "Please fill in bank name",
    missing_recipient_country: "Please fill in recipient country",
    missing_recipient_city: "Please fill in recipient city",
    missing_recipient_address: "Please fill in recipient address ",
    missing_bsb_code: "Please fill in BSB code",
    missing_swift_code: "Please fill in SWIFT code",
    missing_daily_purchase_limit: "Please fill in daily purchase limit",
    missing_daily_atm_limit: "Please fill in daily atm limit",
    missing_sign_image: "Please sign by hand",
    missing_id_image: "Please upload the front/back side of your ID card",
    invalid_id_image: "The front and back of the ID card cannot be the same",
    missing_passport_image: "Please upload passport image",
    missing_payment_method: "Please select payment method",
    bind_card_success: "Bind card success",
    missing_card_number: "Please fill card number",
    transfer_failed: "Transfer in failed",
    transfer_success: "Transfer in success",
    quote_price_failed: "Quote price failed",
    execute_quote_failed: "Execute quote failed",
    get_crypto_fees_failed: "Get crypto fees failed",
    minimum_top_up_amount: `The minimum top up amount is {{minimumExchangeUnit}} {{fromCurrency}}`,
    minimum_withdraw_amount: `The minimum withdraw amount is {{amount}}`,
    insufficient_balance: "Insufficient balance",
    positive_amount_warning: "Please fill in a positive amount",
    get_address_failed: "Failed to get wallet address",
    please_fill_in_display_name: "Please fill in display name",
    please_fill_in_old_password: "Please fill in old password",
    please_fill_in_new_password: "Please fill in new password",
    please_fill_in_confirm_new_password: "Please fill in confirm new password",
    please_fill_in_different_password:
      "Please fill in password different to the old password",
    password_not_match: "Password not matched",
    missing_bank_type: "Please fill in the destination bank type!",
    invalid_amount: "The transfer amount must be greater than 0!",
    invalid_crypto_address: "Please enter a valid crypto address",
    submission_failed: "Submission failed!",
    submission_successful:
      "Submission successful, instructions are being processed...",
    submit_application_success:
      "Your application is processing. It will take 1 business day to process. You will be notified via email once your account is created",
    logout_success: "Logout success!",
    login_password_incorrect: "Incorrect password, you have three chances left",
    password_incorrect: "Incorrect password",
    get_profile_failed: "Failed to retrieve user profile",
    username_not_found: "User not found",
    card_verification_failed: "Card verification does not match the account",
    get_application_status_failed:
      "Failed to get mastercard application status.",
    gc_card_not_found: "GC card not found",
    save_sig_failed: "Save signature failed",
    save_sig_success: "Save signature success",
    update_failed: "Update failed",
    update_success: "Update success",
    withdraw_crypto_failed: "crypto withdrawal request failed",
    unknown_error: "Please try again later.",
    image_format_error: "Only image/jpeg is allowed",
    image_size_error: "The image size should not exceed 200MB",
    email_format_incorrect: "Please enter a valid email address",
    missing_required_field: "Please fill in {{field}}",
    validation_not_passed: "Invalid form submission",
    invalid_date_range: "Invalid {{field}} date range",
    open_account_success: "Open account success!",
    open_account_failed: "Open account failed!",
    create_payment_qr_failed:
      "Payment failed due to a busy network. Please retry later or use a different payment method.",
    submit_bank_deposit_failed: "Submit bank deposit form failed",
    submit_bank_deposit_success: "Submit Bank deposit form success",
  },
  name_review: "Name Review",
  content: "The name has been approved ✅. This user is an approved user.",
  sorry: "Sorry",
  congrats: "Congratulations",
  quick_menu: "Quick Menu",
  quick_menu_item: {
    transfer_in: "Transfer In",
    transfer_out: "Transfer Out",
    convert: "Convert",
  },
  main_menu_item: {
    asset_summary: "Asset Summary",
    card: "Card",
    master_card: "Master Card",
    digital_asset: "Digital Assets",
    securities: "Securities",
    bill_payment: "Bill Payment",
    order_records: "eStatements",
    account_center: "Account Center",
    onshore_wallet: "Onshore Wallet",
  },
  main_menu: "Main Menu",
  logout: "Logout",
  coming_soon: "Coming Soon",
  dashboard: {
    greeting: "Hi {{userDisplayName}}",
    welcome_back_message: "Welcome back to your Rich Dragon Trust Account!",
    total_asset_value: "Total Asset Value",
    fiat_assets: "Fiat Assets",
    other_assets: "Other Assets",
    asset_distribution: "Asset Distribution",
    fiat_account_local_hkd: "Onshore Account (HKD)",
    fiat_account_overseas_usd: "Offshore Account (USD)",
    digital_assets: "Digital Assets",
    card_balance: "Card Balance",
    investment_account: "Investment Account",
    basic_operations: "Basic Operations",
    transfer_in_desc: "Transfer cash as fiat assets",
    convert_desc: "Convert to digital assets or other fiat currencies",
    card_desc: "Apply, top up and use Master Card",
    transfer_out_desc: "Transfer fiat assets",
    onshore_desc: "Onshore transfer-in & out fiat",
  },
  onshore_wallet: {
    selecting: "*Selecting...",
    summary: "Onshore Wallet Summary",
    personal_account_number: "Personal Account Number",
    account_type: "Account Type",
    account_info: "My Account Information",
    open_account: "Open Account",
    deposit: "Deposit",
    withdraw: "Withdraw",
    account_detail: "Account Details",
    unionpay: "UnionPay",
    alipay: "Alipay",
    wechatpay: "WechatPay",
    bank_deposit: "Bank Deposit",
    currency: "Currency",
    onshore_deposit_transfer_amount: "Transfer Amount",
    bank_info: "Bank Information",
    reference: "Reference",
    deposit_payment_qr:
      "You can use the QR code for payments via <span class='text-primary'>{{method}}</span>",
    deposit_declaration:
      "Transfers will first be made to AsiaTop Loyalty Limited for the purpose of activating an account.",
    select_currency: "Select Currency",
    onshore_withdraw_transfer_amount: "Transfer Amount",
    transfer_to: "Transfer To",
    receiver_country: "Receiver Country",
    other_receiver_country: "Receiver Country (Other)",
    local: "Local Back",
    international: "International Bank (SWIFT)",
    bank_name: "Bank Name",
    bsb_code: "BSB",
    swift_code: "SWIFT Code",
    account_name: "Account Name",
    account_number: "Account Number",
    payee_account_name: "Payee Account Name",
    payee_account_number: "Payee Account Number",
    payee_address: "Payee Address",
    payee_city: "Payee City",
  },
  transfer_in: {
    select_currency: "Select Currency",
    form: "Transfer Form",
    currency: "Currency",
    transfer_amount: "Transfer Amount",
    bank_info: "Bank Information",
    instruction_note: "Note:",
    reference: "Reference",
    bank_transfer_note_1:
      "Please transfer to the above bank account and enter yours in the note column",
    bank_transfer_note_2:
      "After the transfer is successful, the fiat currency will appear in your account in one to two working days",
    bank_transfer_note_3:
      "The recharge amount will be subject to the bank deposit (after deducting the handling fee)",
    account_name: "Account Name",
    account_number: "Account Number",
    bank_name: "Bank Name",
    bank_code: "Bank Code",
  },
  transfer_out: {
    form: "Transfer Out Form",
    select_currency: "Select Currency",
    transfer_amount: "Transfer Amount",
    transfer_to: "Transfer To",
    receiver_country: "Receiver Country",
    local_bank: "Local Back",
    international_bank: "International Bank (SWIFT)",
    bank_name: "Bank Name",
    bsb_code: "BSB",
    swift_code: "SWIFT Code",
    account_name: "Account Name",
    account_number: "Account Number",
    payee_account_name: "Payee Account Name",
    payee_account_number: "Payee Account Number",
    payee_address: "Payee Address",
    payee_city: "Payee City",
  },
  convert: "Convert",
  select_token: "Select Token",
  convert_token: "Convert Token",
  exchange_rate_info: "Latest exchange rate is",
  exchange_rate_validity: "Exchange rate is valid within {{timeCount}} seconds",
  calculating: "Calculating...",
  amount: "Amount",
  my_asset: "Balance",
  confirmation_exchange: "Exchange Confirmation",
  card: {
    my_card: "My Card",
    add_card: "Add Card",
    bind_card: "Bind Card",
    add_card_footnote_1:
      "You must have a Card before you can add it to your Rich Dragon Trust account",
    add_card_footnote_2: "If you don't have a card, please",
    add_card_footnote_3:
      "Your card must be authenticated and match the phone number or email address on your account",
    card_number: "Card Number",
    expiry_date: "Expiry Date",
    balance: "Balance",
    freeze_balance: "Freeze Balance",
    top_up_form: "Top Up Form",
    currency: "Currency",
    transfer_amount: "Transfer Amount",
    final_transfer_amount: "Total Transfer Amount",
    international: "International",
    navigate_to_payment_page: "Navigated to the payment page",
  },
  masterCard: {
    my_card: "My Master Card",
    name: "Master Card",
    apply_form: "Master Card Application Form",
    add_card_note_1: "If you don't have a master card, please ",
    add_card_note_2:
      "After you submit your application, it will take 1-3 business days to process. Once your application is approved, you can start using it right away.",
    add_card_note_3: "The master card application fee is HKD 1,000.",
    applied_card_note:
      "Thank you for submitting your application. We have received it successfully. Once your primary card is issued, we will notify you promptly.",
    submitted_card_request:
      "Thank you for your application. It is currently being processed, and we estimate it will take 1-3 working days. We appreciate your patience, and we will notify you once the processing is complete.",
    top_up_form: "Top Up Card Form",
    currency: "Currency",
    transfer_amount: "Transfer Amount",
    upload: "Upload",
    signature: "Handwritten Signature",
    passport_image: "Passport Page Photo",
    passport_document_type_required:
      "Passport is required as the identification document type",
    id_card: "HKID",
    passport: "Passport Number",
    id_face_image: "ID Card Front",
    id_back_title: "ID Card Back",
    id_back_image: "ID Card (Back)",
    document_id_required:
      "Mainland China ID card / Hong Kong Permanent ID card is required as the identification document type",
    confirm: "Confirm To",
    are_you_sure: "Are you sure to {{action}}?",
    card_details: "Card Details",
    card_number: "Card Number",
    card_status: "Card Status",
    expiration_date: "Expiration Date",
    cvv: "CVV",
    cvv_placeholder: "Please check the back of the card",
    balance: "Balance",
    card_limit: "Card Limit",
    available_limit: "Available Limit",
    daily_atm_limit: "Daily ATM Limit",
    daily_purchase_limit: "Daily Purchase Limit",
    card_reported_lost: "Failed",
    card_activated: "Activated",
    unknown: "Unknown",
    modify_daily_limit_form: "Modify Daily Limit Form",
    modify_atm_pin_form: "Modify/Set ATM PIN Form",
    confirm_new_pin: "Confirm New Pin",
    note: "Note:",
    edit_atm_pin_note_1:
      "Your default ATM PIN is displayed on the back of the card. You can choose to change the PIN after receiving the card.",
    edit_atm_pin_note_2: "ATM PIN is ",
    edit_atm_pin_note_3: "a 6-digit number ",
    edit_atm_pin_note_4: "and will take effect immediately once changed.",
    edit_atm_pin_note_5: "ATM PIN will be used when making purchases.",
    start_date: "Start_Date",
    end_date: "End Date",
  },
  crypto_asset: {
    crypto_asset_label: "Crypto Asset",
    crypto_asset_summary: "Crypto Asset Summary",
    create_wallet: "We are creating your wallet",
    choose_network: "Choose Network Chain",
    to_address: "To Address",
    crypto_address: "Crypto Wallet Address",
    contact: "Contact Us",
    amount: "Amount",
    withdraw: "Withdraw",
    network: "Blockchain",
    received_amount: "Total Received Amount",
    fees: "Withdrawal Fees",
    confirm_to_withdraw: "Confirm To Withdraw",
    are_you_sure: "Are you sure to withdraw?",
    transfer_to_address_footnote_1:
      "The crypto wallet address has been updated. Please deposit to the address above.",
    transfer_to_address_footnote_2:
      "Deposits will be credited to the RD Trust wallet within 1-2 business days.",
    transfer_to_address_footnote_3:
      "Deposits can be used for investment or other activities.",
    withdraw_crypto_footnote_1: "The minimum withdraw amount is",
    withdraw_crypto_footnote_2: "Total token amount received",
    withdraw_crypto_footnote_3: "Withdrawals may take up to 2 hours to process",
  },
  transaction: {
    recent_transaction_history: "Recent eStatements",
    view_all_transactions: "View All eStatements",
    no_transaction_record: "No eStatements",
  },
  profile: {
    female: "Female",
    male: "Male",
    passport: "Passport",
    id_card: "ID Card",
    info: "Personal Information",
    username: "Username",
    gender: "Gender",
    surname_en: "Surname (English)",
    first_name_en: "First Name (English)",
    surname_tc: "Surname (Chinese)",
    first_name_tc: "First Name (Chinese)",
    nationality: "Nationality",
    country_code: "Country / Region Code",
    email: "Email",
    phone_num: "Phone Number",
    dob: "Date of Birth",
    residential_address: "Residential Address",
    address_line_1: "Address line 1*",
    address_line_2: "Address line 2 (Optional)",
    city: "City / Town",
    state: "State / Region",
    zip_code: "Zip Code / Postal Code",
    country: "Country",
    position: "Position",
    occupation: "Occupation",
    annual_income: "Annual Income",
    funds_source: "Source of Income",
    document_type: "Document Type (ID/Passport)",
    document_id: "ID / Passport Number",
    document_id_expiry_date: "Document Expiry Date",
  },
  securities: {
    account_info: "Your Account Information",
    login_id: "Login ID",
    password: "password",
    instructions:
      "If you need to trade, please go to the platform and log in using the above account",
    account_not_opened:
      "Your account is inactive. If you need to open the account, please ",
    account_verification_required:
      "Trust accounts require additional verification to initiate investment accounts. Please",
    download_zip: "download, complete, and submit the form.",
  },
  type: {
    all: "All",
    crypto_all: "All",
    deposit: "Deposit",
    onshore_deposit: "Deposit",
    onshore_withdraw: "Withdraw",
    withdraw: "Withdraw",
    onshore: "All",
    crypto_deposit: "Crypto Deposit",
    crypto_withdraw: "Crypto Withdraw",
    convert: "Convert",
    transfer_in: "Transfer In",
    transfer_out: "Transfer Out",
    exchange: "Exchange",
    charge: "Top Up Master Card",
    transfer: "Transfer",
    card: "Top Up Card To Account",
  },
  status: {
    all: "All",
    done: "Done",
    fail: "Fail",
    succeed: "Success",
    processing: "Processing",
    pending: "Processing",
    deduced_handling_fee: "Deduced Handling Fee",
    pending_handling_fee: "Pending handling fee",
  },
  please_select: "Please select",
  payment_method: "Payment Method",
  pay_with: "Pay With {{method}}",
  please_fill_in: "Please fill in",
  bank_transfer: "Bank Transfer",
  qr: "QR Code",
  button: {
    profile: "Profile",
    continue: "Continue",
    goBack: "Back",
    cancel: "Cancel",
    confirm: "Confirm",
    submit: "Submit",
    card_info: "Card Information",
    top_up_account: "Top Up Account",
    top_up_master_card: "Top Up Master Card",
    edit_atm_pin: "Modify ATM Password",
    edit_daily_limit: "Edit Daily limit",
    lock_unlock_card: "Lock/Unlock",
    lock_card: "Lock Card",
    unlock_card: "Unlock Card",
    reload: "Reload",
    done: "Done",
    get_quote: "Get Quote",
    go_to_platform: "Go to Platform",
    search: "Search",
    clear: "Clear",
  },
  modal: {
    require_kyc_one_title: "Required Activation",
    require_kyc_one_text:
      "You will have to deposit 5000 HKD in order to activate your Trust Account.",
    confirm_open_onshore_account: "Open Account Confirmation",
    are_you_sure:
      "Are you sure to open <span class='text-primary'>{{currency}}</span> account?",
    payment_qr: "{{method}} Payment QR",
  },
};
