export const tcTranslation = {
  en: "English",
  tc: "繁體中文",
  sc: "简体中文",
  hkd: "港幣",
  rmb: "人民幣",
  usd: "美元",
  eur: "歐元",
  sgd: "新加坡元",
  local: "本地銀行",
  international: "海外銀行 (SWIFT)",
  australia: "澳洲",
  german: "德國",
  india: "印度",
  indonesia: "印尼",
  china: "中國大陸",
  malaysia: "馬來西亞",
  "new-zealand": "紐西蘭",
  philippines: "菲律賓",
  singapore: "新加坡",
  taiwan: "台灣",
  "united-kingdom": "英國",
  "united-states": "美国",
  vietnam: "美國",
  other: "其他",
  account: "帳號",
  transfer_in_label: "轉入",
  transfer_out_label: "轉出",
  from: "由",
  to: "至",
  balance: "結餘",
  apply_now: "立即申請",
  apply_here: "按此申請。",
  apply_note_1: "申請將在1個工作天內處理",
  apply_note_2: "審核成功後，我們將會發送帳戶資料到您的電郵",
  apply_step_1: "1. 請填寫個人資料表格",
  apply_step_2: "2. 將已填好的表格發送至",
  apply_step_3: "3. 請耐心等候 2-3 工作天",
  apply_step_4: "4. 審核成功後，我們將會發送帳戶資料到您的電郵",
  download: "按此下載",
  login: "登錄",
  form_header: "請確保你正在瀏覽",
  login_to_account: "登入到您的帳戶",
  username: "用戶帳號",
  without_account: "沒有帳戶？",
  password: "密碼",
  desc1: "多合一信託資產管理服務商",
  desc2:
    "作為一家總部位於香港的全球金融機構，富龍信託由來自信託業、資產管理、金融科技、法務合規、金融服務、信用卡和支付等領域的專家共同建設。規範的客戶服務方案。",
  desc3:
    "富龍參考信託法第III部分第77節註冊、保險和許可的信託公司。牌照編號TC009033。",
  change_pwd: "更改密碼",
  old_password: "舊的密碼",
  new_password: "新的密碼",
  confirm_new_password: "確認新的密碼",
  display_name: "顯示名稱",
  edit: "編輯",
  account_password: "帳戶密碼",
  account_center: "帳戶中心",
  edit_account_password: "編輯帳戶密碼",
  handling_fee: "手續費",
  message: {
    missing_username: "請輸入用戶帳號",
    missing_password: "請輸入密碼",
    missing_amount: "請輸入金額",
    missing_asset_type: "請選擇幣種",
    missing_to_address: "請輸入轉至虛擬地址",
    missing_dates: "請選擇起始日期/结束日期",
    missing_account_name: "請填寫帳戶名稱！",
    missing_account_number: "請填寫帳戶號碼！",
    missing_bank_name: "請填寫銀行名稱！",
    missing_recipient_country: "請填寫收款人國家！",
    missing_recipient_city: "請填寫收款人地區！",
    missing_recipient_address: "請填寫收款人地址！",
    missing_bsb_code: "請填寫 BSB！",
    missing_swift_code: "請填寫 SWIFT 碼！",
    missing_daily_purchase_limit: "請輸入每日消費限額",
    missing_daily_atm_limit: "請輸入ATM每日限額",
    missing_sign_image: "請手寫簽名",
    missing_id_image: "請上傳身分證正面/反面",
    invalid_id_image: "身分證正面與反面不能相同",
    missing_passport_image: "請上傳護照影印本",
    missing_payment_method: "請選擇支付方式",
    bind_card_success: "綁定成功",
    missing_card_number: "請輸入卡號",
    transfer_failed: "轉入失敗！",
    transfer_success: "轉入成功！",
    quote_price_failed: "獲取報價失敗",
    execute_quote_failed: "兌換失敗",
    get_crypto_fees_failed: "獲取提幣手續費失敗",
    minimum_top_up_amount: `最低充值金額是{{minimumExchangeUnit}} {{fromCurrency}}`,
    minimum_withdraw_amount: "最低提幣數量是{{amount}}",
    insufficient_balance: "餘額不足",
    positive_amount_warning: "請輸入正數金額",
    get_address_failed: "獲取地址失敗",
    please_fill_in_display_name: "請輸入新顯示名稱",
    please_fill_in_old_password: "請輸入舊的密碼",
    please_fill_in_new_password: "請輸入新的密碼",
    please_fill_in_confirm_new_password: "請輸入確認新的密碼",
    please_fill_in_different_password: "請輸入與舊密碼不同的密碼",
    password_not_match: "新的密碼與確認新的密碼不同符",
    missing_bank_type: "請填寫轉至銀行類別！",
    invalid_amount: "轉出金額必須是正數並大於0！",
    invalid_crypto_address: "無效虛擬地址",
    submission_failed: "遞交失敗！",
    submission_successful: "遞交成功，指示正在處理中...",
    submit_application_success:
      "遞交成功！我們將會在1個工作天內發送帳戶資料到您的電郵",
    logout_success: "已成功登出",
    login_password_incorrect: "密碼不正確，你還有三次機會",
    password_incorrect: "密碼不正確",
    get_profile_failed: "無法獲取用戶資料",
    username_not_found: "用戶不存在",
    card_verification_failed: "卡號認證與帳戶不一",
    gc_card_not_found: "無法找到金融卡卡號",
    get_application_status_failed: "查詢信用卡申請狀態失敗",
    save_sig_failed: "保存失敗",
    save_sig_success: "保存成功",
    update_failed: "更新失敗！",
    update_success: "更新成功！",
    withdraw_crypto_failed: "提幣失敗",
    unknown_error: "請稍後再試",
    image_format_error: "只允許使用 image/jpeg 格式的圖片",
    image_size_error: "圖片大小不得超過 200MB",
    email_format_incorrect: "請墳寫有效的電郵地址",
    missing_required_field: "請墳寫{{field}}",
    validation_not_passed: "提交無效",
    invalid_date_range: "{{field}}日期範圍無效",
    open_account_success: "開通帳戶成功！",
    open_account_failed: "開通帳戶失敗！",
    create_payment_qr_failed: "付款網絡繁忙。請稍後重試或使用其他支付方式。",
    submit_bank_deposit_failed: "遞交表格失敗！",
    submit_bank_deposit_success: "遞交表格成功！",
  },
  name_review: "名稱審核",
  content: "名字已通過審核 ✅，此用戶為正常用戶。",
  sorry: "抱歉",
  congrats: "恭喜",
  quick_menu: "常用功能",
  quick_menu_item: {
    transfer_in: "轉入",
    transfer_out: "轉出",
    convert: "兌換",
  },
  main_menu_item: {
    asset_summary: "資產總覽",
    card: "金融卡",
    master_card: "信用卡",
    digital_asset: "虛擬資產",
    securities: "投資與回報",
    bill_payment: "帳單付款",
    order_records: "指令紀錄",
    account_center: "帳戶中心",
    onshore_wallet: "在岸賬戶",
  },
  main_menu: "主菜單",
  logout: "登出",
  coming_soon: "即將推出",
  dashboard: {
    greeting: "你好 {{userDisplayName}}",
    welcome_back_message: "歡迎回到您的 Rich Dragon Trust 賬戶！",
    total_asset_value: "總資產凈值",
    fiat_assets: "法幣資產",
    other_assets: "其他資產",
    asset_distribution: "資產分佈",
    fiat_account_local_hkd: "在岸賬戶 (本地 HKD)",
    fiat_account_overseas_usd: "離岸賬戶 (海外 USD)",
    digital_assets: "數字資產",
    card_balance: "金融卡餘額",
    investment_account: "投資帳戶",
    basic_operations: "基本操作功能",
    transfer_in_desc: "轉入現金為法幣資產",
    convert_desc: "兌換成數字資產或其他法幣",
    card_desc: "申請、充值及使用信用卡",
    transfer_out_desc: "轉出法幣資產",
    onshore_desc: "在岸帳戶轉入轉出法幣資產",
  },
  onshore_wallet: {
    selecting: "*已選擇",
    summary: "在岸帳戶總覽",
    personal_account_number: "個人帳戶號碼",
    account_type: "帳戶類型",
    account_info: "我的帳號資料",
    open_account: "開通帳戶",
    deposit: "轉入",
    withdraw: "轉出",
    account_detail: "帳戶資料",
    unionpay: "雲閃付",
    alipay: "支付寶",
    wechatpay: "微信",
    bank_deposit: "銀行轉帳",
    currency: "貨幣",
    onshore_deposit_transfer_amount: "轉入金額",
    bank_info: "銀行資料",
    reference: "參考編號",
    deposit_payment_qr:
      "二維碼可適用於<span class='text-primary'>{{method}}</span>支付",
    deposit_declaration:
      "轉帳將會先轉入 AsiaTop Loyalty Limited 作開通帳戶之用",
    select_currency: "選擇貨幣",
    onshore_withdraw_transfer_amount: "轉出金額",
    transfer_to: "轉至",
    receiver_country: "收款人國家",
    other_receiver_country: "收款人國家 (其他)",
    local: "本地銀行",
    international: "海外銀行 (SWIFT)",
    bank_name: "銀行名稱",
    bsb_code: "BSB 碼",
    swift_code: "SWIFT 碼",
    account_name: "帳戶名稱",
    account_number: "帳戶號碼",
    payee_account_name: "收款人帳戶名稱",
    payee_account_number: "收款人帳戶號碼",
    payee_address: "收款人地址",
    payee_city: "收款人地區",
  },
  transfer_in: {
    select_currency: "選擇貨幣",
    form: "轉入表格",
    currency: "貨幣",
    transfer_amount: "轉入金額",
    bank_info: "銀行資料",
    instruction_note: "註：",
    reference: "參考編號",
    bank_transfer_note_1: "請轉帳至以上銀行戶口，並在備註欄輸入你的",
    bank_transfer_note_2: "轉帳成功後，法幣會於一至兩個工作天顯示在你的帳戶裡",
    bank_transfer_note_3: "充值金額會以銀行到帳（扣除手續費後）為準",
    account_name: "帳戶名稱",
    account_number: "帳戶號碼",
    bank_name: "銀行名稱",
    bank_code: "銀行編號",
  },
  transfer_out: {
    form: "轉出表格",
    select_currency: "選擇貨幣",
    transfer_amount: "轉出金額",
    transfer_to: "轉至",
    receiver_country: "收款人國家",
    local_bank: "本地銀行",
    international_bank: "海外銀行 (SWIFT)",
    bank_name: "銀行名稱",
    bsb_code: "BSB 碼",
    swift_code: "SWIFT 碼",
    account_name: "帳戶名稱",
    account_number: "帳戶號碼",
    payee_account_name: "收款人帳戶名稱",
    payee_account_number: "收款人帳戶號碼",
    payee_address: "收款人地址",
    payee_city: "收款人地區",
  },
  convert: "兌換",
  select_token: "選擇幣種",
  convert_token: "兌換幣種",
  exchange_rate_info: "最新兌換率為",
  exchange_rate_validity: "兌換率於{{timeCount}}秒內有效",
  calculating: "計算中...",
  amount: "金額",
  my_asset: "我的資產",
  confirmation_exchange: "確認兌換",
  card: {
    my_card: "我的金融卡",
    add_card: "新增金融卡",
    bind_card: "綁定卡號",
    add_card_footnote_1:
      "您必需先擁有一張金融卡才能新增到 Rich Dragon Trust 帳戶",
    add_card_footnote_2: "如沒有金融卡，請",
    add_card_footnote_3: "您的金融卡必需認證，並與帳戶的電話號碼或電郵一致",
    card_number: "卡號",
    expiry_date: "到期日",
    balance: "餘額",
    freeze_balance: "凍結餘額",
    top_up_form: "充值表格",
    currency: "貨幣",
    transfer_amount: "轉入金額",
    final_transfer_amount: "最終轉入金額",
    international: "國際",
    navigate_to_payment_page: "跳轉至付款網頁",
  },
  masterCard: {
    my_card: "我的信用卡",
    name: "信用卡",
    apply_form: "信用卡申請表",
    add_card_note_1: "如沒有消費信用卡，請 ",
    add_card_note_2:
      "當我們收到您的申請表格後，將於1-3個工作天內處理申請。申請一旦成功，您便可立即使用。",
    add_card_note_3: "申請信用卡費用為1000 HKD。",
    applied_card_note: "我們已收到您的申請。當主卡發放時，您將收到通知。",
    submitted_card_request: "您的申請正在處理中。處理將會1-3個工作天。",
    top_up_form: "充值信用卡表格",
    currency: "貨幣",
    transfer_amount: "轉入金額",
    upload: "上傳",
    signature: "手寫簽名",
    passport_image: "護照頁照片",
    passport_document_type_required: "證件類型為護照必須",
    id_card: "身份證",
    passport: "護照號",
    id_face_image: "身分證正面",
    id_back_title: "身分證反面",
    id_back_image: "身份證 (反面)",
    document_id_required: "證件類型為大陸身分證/香港永久身分證必須",
    confirm: "確定",
    are_you_sure: "你要確認{{action}}嗎？",
    card_details: "卡號詳情",
    card_number: "卡號",
    card_status: "卡號狀態",
    expiration_date: "到期日",
    cvv: "CVV",
    cvv_placeholder: "請查閱卡背面",
    balance: "餘額",
    card_limit: "卡限額",
    available_limit: "可用限額",
    daily_atm_limit: "每日ATM限額",
    daily_purchase_limit: "每日消費限額",
    card_reported_lost: "已掛失",
    card_activated: "已激活",
    unknown: "不明",
    modify_daily_limit_form: "修改每日限額表格",
    modify_atm_pin_form: "修改/設置ATM密碼表格",
    confirm_new_pin: "確認新的密碼",
    note: "註：",
    edit_atm_pin_note_1:
      "你的預設ATM密碼顯示在卡背面。你可選擇在收取卡後修改密碼。",
    edit_atm_pin_note_2: "ATM密碼顯為",
    edit_atm_pin_note_3: "6位數字",
    edit_atm_pin_note_4: "，密碼一旦修改並會即時生效。",
    edit_atm_pin_note_5: "ATM密碼將會在你消費時使用。",
    start_date: "起始日期",
    end_date: "结束日期",
  },
  crypto_asset: {
    crypto_asset_label: "虛擬資產",
    crypto_asset_summary: "虛擬錢包總覽",
    create_wallet: "我們正在建立你的錢包",
    choose_network: "選擇虛擬網絡",
    to_address: "轉出地址",
    crypto_address: "虛擬銀包地址",
    contact: "聯絡我們",
    amount: "數量",
    withdraw: "提幣",
    network: "虛擬網絡",
    received_amount: "最終轉出數量",
    fees: "提幣手續費",
    confirm_to_withdraw: "確認提幣",
    are_you_sure: "你要確認提幣嗎？",
    transfer_to_address_footnote_1: "虛擬錢包地已更新。請存入以上地址",
    transfer_to_address_footnote_2: "存款將在 1-2 個工作天內存入RD Trust 錢包",
    transfer_to_address_footnote_3: "存款可在投資 / 其他活動",
    withdraw_crypto_footnote_1: "最低提幣數量",
    withdraw_crypto_footnote_2: "最終轉入虛擬貨幣數量",
    withdraw_crypto_footnote_3: "提幣可能需要最多2個小時的處理時間",
  },
  transaction: {
    recent_transaction_history: "最近交易紀錄",
    view_all_transactions: "最終轉入虛擬貨幣數量",
    no_transaction_record: "提幣可能需要最多2個小時的處理時間",
  },
  profile: {
    female: "女",
    male: "男",
    id_card: "身分證",
    passport: "護照",
    info: "個人資料",
    username: "用戶名稱",
    gender: "性別",
    surname_en: "姓 (英文)",
    first_name_en: "名 (英文)",
    surname_tc: "姓 (中文)",
    first_name_tc: "名 (中文)",
    nationality: "國籍",
    country_code: "國家地區代碼",
    email: "電子郵箱",
    phone_num: "手機號碼",
    dob: "出生日期",
    residential_address: "住宅地址",
    address_line_1: "地址行 1",
    address_line_2: "地址行 2 (可選填寫)",
    city: "城市 / 城鎮",
    state: "州 / 地區",
    zip_code: "郵遞區號",
    country: "國家",
    position: "職位",
    occupation: "職業",
    annual_income: "年收入",
    funds_source: "收入來源",
    document_type: "證件類型 (身份證/護照)",
    document_id: "身分證字號/護照號",
    document_id_expiry_date: "證件有效期至",
  },
  securities: {
    account_info: "你的帳戶資料",
    login_id: "登入 ID",
    password: "密碼",
    instructions: "如需交易，請前往平台登錄以上帳號",
    account_not_opened: "帳號暫時未開啟。如需開啟帳號，請 ",
    account_verification_required: "Trust 帳戶需要更高驗證以開啟投資帳戶，請",
    download_zip: "下載表格填寫並遞交",
  },
  type: {
    all: "全部",
    crypto_all: "全部",
    deposit: "存入",
    onshore_deposit: "存入",
    onshore_withdraw: "轉出",
    withdraw: "提幣",
    onshore: "全部",
    crypto_deposit: "存幣",
    crypto_withdraw: "提幣",
    confirm_to_withdraw: "確認提幣",
    are_you_sure: "你要確認提幣嗎？",
    convert: "兌換",
    transfer_in: "轉入",
    transfer_out: "轉出",
    exchange: "兌換",
    charge: "充值信用卡",
    transfer: "轉帳",
    card: "卡充值到帳戶",
  },
  status: {
    all: "全部",
    done: "成功",
    fail: "失敗",
    succeed: "成功",
    processing: "處理中",
    pending: "處理中",
    deduced_handling_fee: "扣減手續費",
    pending_handling_fee: "待處理手續費",
  },
  please_select: "讓選擇",
  payment_method: "支付方式",
  pay_with: "{{method}}支付",
  please_fill_in: "請輸入",
  bank_transfer: "銀行轉帳",
  qr: "二維碼",
  button: {
    profile: "我的資料",
    continue: "繼續",
    goBack: "返回",
    cancel: "取消",
    confirm: "確定",
    submit: "遞交",
    card_info: "卡號詳情",
    top_up_account: "付款充值到帳戶",
    top_up_master_card: "充值信用卡",
    edit_atm_pin: "修改/設置ATM密碼",
    edit_daily_limit: "修改每日限額",
    lock_unlock_card: "鎖卡/解鎖",
    lock_card: "鎖卡",
    unlock_card: "解鎖",
    reload: "重新整理",
    done: "已完成",
    get_quote: "獲取報價",
    go_to_platform: "前往平台",
    search: "搜尋",
    clear: "清除",
  },
  modal: {
    require_kyc_one_title: "帳戶未激活",
    require_kyc_one_text: "需要存入 5000 HKD 以激活你的 Trust 帳戶。",
    confirm_open_onshore_account: "確認開通帳戶",
    are_you_sure:
      "你確認要開通 <span class='text-primary'>{{currency}}</span> 帳戶嗎？",
    payment_qr: "支付二維碼",
  },
};
