import { CRYPTO_ASSET_ACTION_LIST } from "src/config/crypto-asset";
import { CryptoAssetAggregateBalance } from "./crypto-asset-aggregate.balance";
import { CryptoAssetWalletToken } from "./crypto-asset-wallet-token";
import { useTranslation } from "react-i18next";
import { ICryptoBalanceProps } from "src/type/crypto";

export interface ICryptoAssetWalletSummaryProps {
  balance: number | null;
  balanceInHkd: number | null;
  activeIdx: number;
  handleActionIdx: (index: number) => void;
  walletTokenList: Array<ICryptoBalanceProps>;
}

export const CryptoAssetWalletSummary = (
  props: ICryptoAssetWalletSummaryProps
) => {
  const { t } = useTranslation();
  const { balance, balanceInHkd, activeIdx, handleActionIdx, walletTokenList } =
    props;

  return (
    <div className="w-full lg:w-[650px] bg-white rounded-s drop-shadow p-lg flex-wrap">
      {balance && (
        <>
          <h3 className="normal-case">
            {t("crypto_asset.crypto_asset_summary")}
          </h3>
          <div className="flex flex-col lg:flex-row">
            <div>
              {/* Aggregate Balance & Wallet Token */}
              <CryptoAssetAggregateBalance
                balance={balance}
                balanceInHkd={balanceInHkd}
              />
              <CryptoAssetWalletToken walletTokenList={walletTokenList} />
            </div>
            {/* Crypto Asset Action Button */}
            <div className="flex lg:flex-1 lg:flex-col items-center lg:items-end mt-m space-x-s lg:space-x-0">
              {CRYPTO_ASSET_ACTION_LIST.map((item: string, i: number) => {
                let selectedButton =
                  activeIdx === i
                    ? "button-gradient"
                    : "inactive-button-gradient";
                return (
                  <div key={i} className="mb-s">
                    <button
                      className={`${selectedButton} button-gradient !text-[12px] !px-lg !p-xs lg:!px-s lg:!w-[140px]`}
                      onClick={() => handleActionIdx(i)}
                    >
                      {t(item)}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      {/* No Crypto Wallet */}
      {!balance && (
        <div className="flex flex-col justify-center items-center min-w-[250px] lg:min-w-[600px] min-h-[300px]">
          <p className="text-center text-content">
            {t("crypto_asset.create_wallet")}
          </p>
          <div className="w-full lg:w-[400px] mt-s">
            <button
              className="button-gradient"
              onClick={() =>
                window.open(
                  `mailto:info@richdragon.io?subject=聯絡我們&body=請輸入您想聯絡我們的原因`
                )
              }
            >
              {t("crypto_asset.contact")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
