export const scTranslation = {
  en: "English",
  tc: "繁体中文",
  sc: "简体中文",
  hkd: "港币",
  rmb: "人民币",
  usd: "美元",
  eur: "欧元",
  sgd: "新加坡元",
  local: "当地银行",
  international: "海外银行 (SWIFT)",
  australia: "澳大利亚",
  german: "德国",
  india: "印度",
  indonesia: "印度尼西亚",
  china: "中国大陆",
  malaysia: "马来西亚",
  "new-zealand": "新西兰",
  philippines: "菲律宾",
  singapore: "新加坡",
  taiwan: "台湾",
  "united-kingdom": "英国",
  "united-states": "美国",
  vietnam: "美国",
  other: "其他",
  account: "帐号",
  transfer_in_label: "转入",
  transfer_out_label: "转出",
  from: "由",
  to: "至",
  balance: "结余",
  apply_now: "立即申请",
  apply_here: "按此申请。",
  apply_note_1: "申请将在 1个 工作天内处理",
  apply_note_2: "审核成功后，我们将会发送帐户资料到您的电邮",
  apply_step_1: "1. 请填写个人资料表格",
  apply_step_2: "2. 将已填好的表格发送至",
  apply_step_3: "3. 请耐心等候 2-3 工作天",
  apply_step_4: "4. 审核成功后，我们将会发送帐户资料到您的电邮",
  download: "按此下载",
  login: "登录",
  form_header: "请确保你正在浏览",
  login_to_account: "登入到您的帐户",
  username: "用户帐号",
  without_account: "没有帐户？",
  password: "密码",
  desc1: "多合一信托资产管理服务商",
  desc2:
    "作为一家总部位于香港的全球金融机构，富龙信托由来自信托业、资产管理、金融科技、法务合规、金融服务、信用卡和支付等领域的专家共同组建。致力于提供先进且合 规的客户服务方案。",
  desc3:
    "富龙依据信托法第III部分第77节注册、保险和许可的信托公司。牌照编号TC009033。",
  change_pwd: "更改密码",
  old_password: "旧的密码",
  new_password: "新的密码",
  confirm_new_password: "确认新的密码",
  display_name: "显示名称",
  edit: "编辑",
  account_password: "帐户密码",
  account_center: "帐户中心",
  edit_account_password: "编辑帐户密码",
  handling_fee: "手续费",
  message: {
    missing_username: "请输入用户帐号",
    missing_password: "请输入密码",
    missing_amount: "请输入金额",
    missing_asset_type: "请选择币种",
    missing_to_address: "请输入转至虚拟地址",
    missing_dates: "请选择起始日期/结束日期",
    missing_account_name: "请填写帐户名称！",
    missing_account_number: "请填写帐户号码！",
    missing_bank_name: "请填写银行名称！",
    missing_recipient_country: "请填写收款人国家！",
    missing_recipient_city: "请填写收款人地区！",
    missing_recipient_address: "请填写收款人地址！",
    missing_bsb_code: "请填写 BSB！",
    missing_swift_code: "请填写 SWIFT 码！",
    missing_daily_purchase_limit: "请输入每日消费限额",
    missing_daily_atm_limit: "请输入ATM每日限额",
    missing_sign_image: "请手写签名",
    missing_id_image: "请上传身分证正面/反面",
    invalid_id_image: "身分证正面与反面不能相同",
    missing_passport_image: "请上传护照影印本",
    missing_payment_method: "请选择支付方式",
    bind_card_success: "绑定成功",
    missing_card_number: "请输入卡号",
    transfer_failed: "转入失败！",
    transfer_success: "转入成功！",
    quote_price_failed: "获取报价失败",
    execute_quote_failed: "兑换失败",
    get_crypto_fees_failed: "获取提币手续费失败",
    minimum_top_up_amount: `最低充值金额是{{minimumExchangeUnit}} {{fromCurrency}}`,
    minimum_withdraw_amount: `最低提币数量是{{amount}}`,
    insufficient_balance: "余额不足",
    positive_amount_warning: "请输入正数金额",
    get_address_failed: "获取地址失败",
    please_fill_in_display_name: "请输入新显示名称",
    please_fill_in_old_password: "请输入旧的密码",
    please_fill_in_new_password: "请输入新的密码",
    please_fill_in_confirm_new_password: "请输入确认新的密码",
    please_fill_in_different_password: "请输入与旧密码不同的密码",
    password_not_match: "新的密码与确认新的密码不同符",
    missing_bank_type: "请填写转至银行类别！",
    invalid_amount: "转出金额必须是正数并大于0！",
    invalid_crypto_address: "无效虚拟地址",
    submission_failed: "递交失败！",
    submission_successful: "递交成功，指示正在处理中...",
    submit_application_success:
      "递交成功！我们将会在1个工作天内发送帐户资料到您的电邮",
    logout_success: "已成功登出",
    login_password_incorrect: "密码不正确，你还有三次机会",
    password_incorrect: "密码不正确",
    get_profile_failed: "无法获取用户资料",
    username_not_found: "用户不存在",
    card_verification_failed: "卡号认证与帐户不一",
    gc_card_not_found: "无法找到金融卡卡号",
    get_application_status_failed: "查询信用卡申请状态失败",
    save_sig_failed: "保存失败",
    save_sig_success: "保存成功",
    update_failed: "更新失败！",
    update_success: "更新成功！",
    withdraw_crypto_failed: "提币失败",
    unknown_error: "请稍后再试",
    image_format_error: "只允许使用 image/jpeg 格式的图片",
    image_size_error: "图片大小不得超过 200MB",
    email_format_incorrect: "请坟写有效的电邮地址",
    missing_required_field: "请填写{{field}}",
    validation_not_passed: "提交无效",
    invalid_date_range: "{{field}}日期范围无效",
    open_account_success: "开通帐户成功！",
    open_account_failed: "开通帐户失败！",
    create_payment_qr_failed: "付款网络繁忙。请稍后重试或使用其他支付方式。",
    submit_bank_deposit_failed: "递交表格失败！",
    submit_bank_deposit_success: "递交表格成功！",
  },
  name_review: "名称审核",
  content: "名字已通过审核 ✅，此用户为正常用户。",
  sorry: "抱歉",
  congrats: "恭喜",
  quick_menu: "常用功能",
  quick_menu_item: {
    transfer_in: "转入",
    transfer_out: "转出",
    convert: "兑换",
  },
  main_menu_item: {
    asset_summary: "资产总览",
    card: "金融卡",
    master_card: "信用卡",
    digital_asset: "虚拟资产",
    securities: "投资与回报",
    bill_payment: "帐单付款",
    order_records: "指令纪录",
    account_center: "帐户中心",
    onshore_wallet: "在岸帐户",
  },
  main_menu: "主菜单",
  logout: "登出",
  coming_soon: "即将推出",
  dashboard: {
    greeting: "你好 {{userDisplayName}}",
    welcome_back_message: "欢迎回到您的 Rich Dragon Trust 账户！",
    total_asset_value: "总资产净值",
    fiat_assets: "法币资产",
    other_assets: "其他资产",
    asset_distribution: "资产分布",
    fiat_account_local_hkd: "在岸账户 (本地 HKD)",
    fiat_account_overseas_usd: "离岸账户 (海外 USD)",
    digital_assets: "数字资产",
    card_balance: "金融卡余额",
    investment_account: "投资帐户",
    basic_operations: "基本操作功能",
    transfer_in_desc: "转入现金为法币资产",
    convert_desc: "兑换成数字资产或其他法币",
    card_desc: "申请、充值及使用信用卡",
    transfer_out_desc: "转出法币资产",
    onshore_desc: "在岸帐户转入转出法币资产",
  },
  onshore_wallet: {
    selecting: "*已选择",
    summary: "在岸帐户总览",
    personal_account_number: "个人帐户号码",
    account_type: "帐户类型",
    account_info: "我的帐户资料",
    open_account: "开通帐户",
    deposit: "转入",
    withdraw: "转出",
    account_detail: "帐户资料",
    unionpay: "云闪付",
    alipay: "支付宝",
    wechatpay: "微信",
    bank_deposit: "银行转帐",
    currency: "货币",
    onshore_deposit_transfer_amount: "转入金额",
    bank_info: "银行资料",
    reference: "参考编号",
    deposit_payment_qr: "二维码可适用于{{method}}支付",
    deposit_declaration:
      "转帐将会先转入 AsiaTop Loyalty Limited 作开通帐户之用",
    select_currency: "选择货币",
    onshore_withdraw_transfer_amount: "转出金额",
    transfer_to: "转至",
    receiver_country: "收款人国家",
    other_receiver_country: "收款人国家 (其他)",
    local: "本地银行",
    international: "海外银行 (SWIFT)",
    bank_name: "银行名称",
    bsb_code: "BSB 码",
    swift_code: "SWIFT 码",
    account_name: "帐户名称",
    account_number: "帐户号码",
    payee_account_name: "收款人帐户名称",
    payee_account_number: "收款人帐户号码",
    payee_address: "收款人地址",
    payee_city: "收款人地区",
  },
  transfer_in: {
    select_currency: "选择货币",
    form: "转入表格",
    currency: "货币",
    transfer_amount: "转入金额",
    bank_info: "银行资料",
    instruction_note: "注：",
    reference: "参考编号",
    bank_transfer_note_1: "请转帐至以上银行户口，并在备注栏输入你的",
    bank_transfer_note_2: "转帐成功后，法币会于一至两个工作天显示在你的帐户里",
    bank_transfer_note_3: "充值金额会以银行到帐（扣除手续费后）为准",
    account_name: "帐户名称",
    account_number: "帐户号码",
    bank_name: "银行名称",
    bank_code: "银行编号",
  },
  transfer_out: {
    form: "转出表格",
    select_currency: "选择货币",
    transfer_amount: "转出金额",
    transfer_to: "转至",
    receiver_country: "收款人国家",
    local_bank: "本地银行",
    international_bank: "海外银行 (SWIFT)",
    bank_name: "银行名称",
    bsb_code: "BSB 码",
    swift_code: "SWIFT 码",
    account_name: "帐户名称",
    account_number: "帐户号码",
    payee_account_name: "收款人帐户名称",
    payee_account_number: "收款人帐户号码",
    payee_address: "收款人地址",
    payee_city: "收款人地区",
  },
  convert: "兑换",
  select_token: "选择币种",
  convert_token: "兑换币种",
  exchange_rate_info: "最新兑换率为",
  exchange_rate_validity: "兑换率于{{timeCount}}秒内有效",
  calculating: "计算中...",
  amount: "金额",
  my_asset: "我的资产",
  confirmation_exchange: "确认兑换",
  card: {
    my_card: "我的金融卡",
    add_card: "新增金融卡",
    bind_card: "绑定卡号",
    add_card_footnote_1:
      "您必需先拥有一张金融卡才能新增到 Rich Dragon Trust 帐户",
    add_card_footnote_2: "如没有金融卡，请",
    add_card_footnote_3: "您的金融卡必需认证，并与帐户的电话号码或电邮一致",
    card_number: "卡号",
    expiry_date: "到期日",
    balance: "余额",
    freeze_balance: "冻结余额",
    top_up_form: "充值表格",
    currency: "货币",
    transfer_amount: "转入金额",
    final_transfer_amount: "最终转入金额",
    international: "国际",
    navigate_to_payment_page: "跳转至付款网页",
  },
  masterCard: {
    my_card: "我的信用卡",
    name: "信用卡",
    apply_form: "信用卡申请表",
    add_card_note_1: "如没有消费信用卡，请 ",
    add_card_note_2:
      "当我们收到您的申请表格后，将于1-3个工作天内处理申请。申请一旦成功，您便可立即使用。",
    add_card_note_3: "申请信用卡费用为1000 HKD。",
    applied_card_note: "我们已收到您的申请。当主卡发放时，您将收到通知。",
    submitted_card_request: "您的申请正在处理中。处理将会1-3个工作天。",
    top_up_form: "充值信用卡表格",
    currency: "货币",
    transfer_amount: "转入金额",
    upload: "上传",
    signature: "手写签名",
    passport_image: "护照页照片",
    passport_document_type_required: "证件类型为护照必须",
    id_card: "身份证",
    passport: "护照号",
    id_face_image: "身分证正面",
    id_back_title: "身分证反面",
    id_back_image: "身份证 (反面)",
    document_id_required: "证件类型为大陆身分证/香港永久身分证必须",
    confirm: "确定",
    are_you_sure: "你要确认{{action}}吗？",
    card_details: "卡号详情",
    card_number: "卡号",
    card_status: "卡号状态",
    expiration_date: "到期日",
    cvv: "CVV",
    cvv_placeholder: "请查阅卡背面",
    balance: "余额",
    card_limit: "卡限额",
    available_limit: "可用限额",
    daily_atm_limit: "每日ATM限额",
    daily_purchase_limit: "每日消费限额",
    card_reported_lost: "已挂失",
    card_activated: "已激活",
    unknown: "不明",
    modify_daily_limit_form: "修改每日限额表格",
    modify_atm_pin_form: "修改/设置ATM密码表格",
    confirm_new_pin: "确认新的密码",
    note: "注：",
    edit_atm_pin_note_1:
      "你的预设ATM密码显示在卡背面。你可选择在收取卡后修改密码。",
    edit_atm_pin_note_2: "ATM密码显为",
    edit_atm_pin_note_3: "6位数字",
    edit_atm_pin_note_4: "，密码一旦修改并会即时生效。",
    edit_atm_pin_note_5: "ATM密码将会在你消费时使用。",
    start_date: "起始日期",
    end_date: "结束日期",
  },
  crypto_asset: {
    crypto_asset_label: "虚拟资产",
    crypto_asset_summary: "虚拟钱包总览",
    create_wallet: "我们正在建立你的钱包",
    choose_network: "选择虚拟网络",
    to_address: "转出地址",
    crypto_address: "虚拟银包地址",
    contact: "联系我们",
    amount: "数量",
    withdraw: "提币",
    network: "虚拟网络",
    received_amount: "最终转出量数",
    fees: "提币手续费",
    confirm_to_withdraw: "确认提币",
    are_you_sure: "你要确认提币吗？",
    transfer_to_address_footnote_1: "虚拟钱包地已更新。请存入以上地址",
    transfer_to_address_footnote_2: "存款将在 1-2 个工作天内存入RD Trust 钱包",
    transfer_to_address_footnote_3: "存款可在投资 / 其他活动",
    withdraw_crypto_footnote_1: "最低提币数量是",
    withdraw_crypto_footnote_2: "最终转入虚拟货币数量",
    withdraw_crypto_footnote_3: "提币可能需要最多2个小时的处理时间",
  },
  transaction: {
    recent_transaction_history: "最近交易纪录",
    view_all_transactions: "查看全部交易",
    no_transaction_record: "没有交易纪录",
  },
  profile: {
    female: "女",
    male: "男",
    passport: "护照",
    id_card: "身分证",
    info: "个人资料",
    username: "用户名称",
    gender: "性别",
    surname_en: "姓 (英文)",
    first_name_en: "名 (英文)",
    surname_tc: "姓 (中文)",
    first_name_tc: "名 (中文)",
    nationality: "国籍",
    country_code: "国家地区代码",
    email: "电子邮箱",
    phone_num: "手机号码",
    dob: "出生日期",
    residential_address: "住宅地址",
    address_line_1: "地址行 1",
    address_line_2: "地址行 2 (可选填写)",
    city: "城市 / 城镇",
    state: "州 / 地区",
    zip_code: "邮递区号",
    country: "国家",
    position: "职位",
    occupation: "职业",
    annual_income: "年收入",
    funds_source: "收入来源",
    document_type: "证件类型 (身份证/护照)",
    document_id: "身分证字号/护照号",
    document_id_expiry_date: "证件有效期至",
  },
  securities: {
    account_info: "你的帐户资料",
    login_id: "登入 ID",
    password: "密码",
    instructions: "如需交易，请前往平台登录以上帐号",
    account_not_opened: "帐号暂时未开启。如需开启帐号，请 ",
    account_verification_required: "Trust 帐户需要更高验证以开启投资帐户，请",
    download_zip: "下载表格填写并递交",
  },
  type: {
    all: "全部",
    crypto_all: "全部",
    deposit: "存入",
    onshore_deposit: "存入",
    onshore_withdraw: "转出",
    withdraw: "提币",
    onshore: "全部",
    crypto_deposit: "存币",
    crypto_withdraw: "提币",
    convert: "兑换",
    transfer_in: "转入",
    transfer_out: "转出",
    exchange: "兑换",
    charge: "充值信用卡",
    transfer: "转账",
    card: "卡充值到帐户",
  },
  status: {
    all: "全部",
    done: "成功",
    fail: "失败",
    succeed: "成功",
    processing: "处理中",
    pending: "处理中",
    deduced_handling_fee: "扣减手续费",
    pending_handling_fee: "待处理手续费",
  },
  please_select: "请选择",
  payment_method: "支付方式",
  pay_with: "{{method}}支付",
  please_fill_in: "请输入",
  bank_transfer: "银行转帐",
  qr: "二维码",
  button: {
    profile: "我的资料",
    continue: "继续",
    goBack: "返回",
    cancel: "取消",
    confirm: "确认",
    submit: "递交",
    card_info: "卡号详情",
    top_up_account: "付款充值到帐户",
    top_up_master_card: "充值信用卡",
    edit_atm_pin: "修改/设置ATM密码",
    edit_daily_limit: "修改每日限额",
    lock_unlock_card: "锁卡/解锁",
    lock_card: "锁卡",
    unlock_card: "解锁",
    reload: "重新整理",
    done: "已完成",
    get_quote: "获取报价",
    go_to_platform: "前往平台",
    search: "搜寻",
    clear: "清除",
  },
  modal: {
    require_kyc_one_title: "帐户未激活",
    require_kyc_one_text: "需要存入 5000 HKD 以激活你的 Trust 帐户。",
    confirm_open_onshore_account: "确认开通帐户",
    are_you_sure:
      "你确认要开通 <span class='text-primary'>{{currency}}</span> 帐户吗？",
    payment_qr: "{{method}}支付二维码",
  },
};
